<!-- @format -->

<template>
    <div class="layout-box">
        <div class="left-right-layout">
            <div :class="['left-layout', showLeft ? '' : 'hideLeft']">
                <slot name="leftLayout" />
            </div>
            <div :class="['right-layout', showLeft ? '' : 'hideLeft']">
                <slot name="rightLayout" />
                <div :class="['link-layout', showLeft ? '' : 'hideLeft']" @click="changeLayout">
                    <div class="arrow">
                        <i class="el-icon-arrow-left" v-show="showLeft" @click="resize"></i>
                        <i class="el-icon-arrow-right" v-show="!showLeft" @click="resize"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import echarts from 'echarts'
export default {
    data() {
        return {
            showLeft: true
        }
    },
    methods: {
        changeLayout() {
            this.showLeft = !this.showLeft
        },
        resize() {
            let mychart = echarts.init(document.getElementById('myecharts'))
            if (mychart) {
                console.log('echarts----------', mychart)
                setTimeout(() => {
                    mychart.resize()
                }, 300);
                
            }
        }
    }
}
</script>

<style scoped lang="scss">
.layout-box {
    width: 100%;
    height: 100%;
    .left-right-layout {
        position: relative;
        display: flex;
        height: 100%;
        .left-layout {
            width: 20%;
            transition: all 0.3s;
            &.hideLeft {
                width: 0;
                transform: translateX(-100vw);
            }
        }
        .right-layout {
            position: relative;
            transition: all 0.3s;
            width: 80%;
            height: 100%;
            overflow: hidden;
            &.hideLeft {
                width: 100%;
            }
            .link-layout {
                position: absolute;
                top: 45px;
                left: 0;
                transition: all 0.3s;
                cursor: pointer;
                width: 20px;
                .arrow {
                    position: relative;
                    height: 100%;
                    i {
                        width: 15px;
                        text-align: center;
                        position: absolute;
                        top: 40%;
                        left: 2px;
                        transform: translateY(-50%);
                        background: #072965;
                        color: #ffffff;
                        height: 40px;
                        line-height: 40px;
                        border-radius: 10px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
</style>
